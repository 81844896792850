import { Component, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { BaseControlComponent } from '../../utils/base-control.component';

@Component({
  selector: 'ngx-form-toggle',
  template: `<div class="custom-checkbox" [ngClass]="{'custom-control': isCheckboxType}">
  <ng-container *ngIf="isCheckboxType">
    <!--suppress HtmlFormInputWithoutLabel -->
    <input class="custom-control-input"
           [title]="title"
           [ngClass]="{'is-invalid': invalid, 'is-valid': valid}"
           type="checkbox"
           [id]="id"
           [required]="required"
           [(ngModel)]="value">
    <label class="custom-control-label" [for]="id">{{label}}</label>
  </ng-container>

  <ng-container *ngIf="isToggleType">
    <input class="custom-control-input tgl tgl-light"
           [title]="title"
           [ngClass]="{'is-invalid': invalid, 'is-valid': valid}"
           type="checkbox"
           [id]="id"
           [required]="required"
           [(ngModel)]="value">
    <label class="tgl-btn" [for]="id"></label>
    <label class="tgl-label" [for]="id" *ngIf="label">{{label}}</label>
  </ng-container>

  <div class="valid-feedback" *ngIf="valid && validMessage">{{validMessage}}</div>

  <div class="invalid-feedback" *ngIf="errorMessages && errorMessages.length">
    <span *ngFor="let message of errorMessages; let last = last;">
      {{message}}<br *ngIf="!last">
    </span>
  </div>
</div>
`,
  styles: [`.tgl{display:none}.tgl,.tgl *,.tgl :after,.tgl :before,.tgl+.tgl-btn,.tgl:after,.tgl:before{box-sizing:border-box}.tgl ::-moz-selection,.tgl :after::-moz-selection,.tgl :before::-moz-selection,.tgl+.tgl-btn::-moz-selection,.tgl::-moz-selection,.tgl:after::-moz-selection,.tgl:before::-moz-selection{background:0 0}.tgl ::selection,.tgl :after::selection,.tgl :before::selection,.tgl+.tgl-btn::selection,.tgl::selection,.tgl:after::selection,.tgl:before::selection{background:0 0}.tgl+.tgl-btn{outline:0;display:inline-block;vertical-align:middle;margin-right:15px;width:4em;height:2em;position:relative;cursor:pointer;margin-bottom:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.tgl+.tgl-btn:after,.tgl+.tgl-btn:before{position:relative;display:block;content:"";width:50%;height:100%}.tgl+.tgl-btn:after{left:0}.tgl+.tgl-btn:before{display:none}.tgl:checked+.tgl-btn:after{left:50%}.tgl-light+.tgl-btn{background:#f0f0f0;border-radius:2em;padding:2px;transition:all .4s ease}.tgl-light+.tgl-btn:after{border-radius:50%;background:#fff;transition:all .2s ease}.tgl-light:checked+.tgl-btn{background:#9fd6ae}.custom-control-input~.tgl-label{margin-bottom:0;vertical-align:middle}.custom-control-input.is-invalid~.tgl-label{color:#dc3545}.custom-control-input.is-valid~.tgl-label{color:#28a745}`],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: FormToggleComponent, multi: true},
    {provide: NG_VALIDATORS, useExisting: FormToggleComponent, multi: true}
  ]
})
export class FormToggleComponent extends BaseControlComponent {

  @Input() type: 'checkbox' | 'toggle' = 'checkbox';
  private _innerValue = false;
  private _isTouch = false;

  get value(): boolean {
    return this._innerValue;
  }

  set value(value: boolean) {
    this._isTouch = true;
    this._innerValue = value;
    this.triggerChange();
  }

  get invalid(): boolean {
    if (this.hasCustomError) {
      return true;
    }

    if (!this._isTouch) {
      return false;
    }

    return this.hasRequiredError;
  }

  get valid(): boolean {
    if (this.hasCustomError) {
      return false;
    }

    if (!this._isTouch) {
      return false;
    }

    return !this.hasRequiredError;
  }

  get errorMessages(): Array<string> {
    if (this.hasCustomError) {
      return this.innerCustomErrorMessages;
    }

    if (this.hasRequiredError) {
      return [this.requiredErrorMessage];
    }
  }

  get isCheckboxType() {
    return 'checkbox' === this.type;
  }

  get isToggleType() {
    return !this.isCheckboxType;
  }

  writeValue(value: boolean): void {
    this._innerValue = value;
  }

  validate(): ValidationErrors {
    const result = {};

    if (this.hasRequiredError) {
      result['required'] = true;
    }

    return result;
  }

  reset() {
    this._isTouch = false;
  }

}
